import React, { useEffect, useState } from "react";
import { HubSpotUser, hubSpotService } from "../../services/HubSpotService";
import { Box, Button, Typography, LinearProgress } from "@mui/material";
import { Book, bookService } from "../../services/BookService";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const TerritoryViewSetupTwo: React.FC = () => {
  const [bookName] = React.useState<string>("");
  const [selectedOwnership] = React.useState<string | null>(null);
  const [selectedBalancing] = React.useState<string | null>(null);
  const [selectedUsers] = React.useState<HubSpotUser[]>([]);

  // Data
  const [, setUsers] = useState<HubSpotUser[]>([]);

  // Misc
  const [loading, setLoading] = useState<boolean>(true);
  const [, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  const { userProfile, token } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response] = await Promise.all([hubSpotService.getUsers()]);

        setUsers(response.data);
      } catch (error) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCreateBookOfBusiness = () => {
    const cleanBook = bookName.trim();
    const cleanOwnership = selectedOwnership?.trim();
    const cleanBalance = selectedBalancing?.trim();
    const cleanUsers = selectedUsers;

    if (!cleanBook) {
      window.alert("Please create a name for your book of business.");
      return;
    }
    if (!cleanOwnership) {
      window.alert("Please select an ownership field.");
      return;
    }
    if (!cleanBalance) {
      window.alert("Please select a field for balancing books.");
      return;
    }
    if (cleanUsers.length === 0) {
      window.alert("Please select a user.");
      return;
    }

    if (
      cleanBook &&
      cleanOwnership &&
      cleanBalance &&
      cleanUsers.length !== 0
    ) {
      console.log(cleanBook, cleanOwnership, cleanBalance, cleanUsers);

      handleUpdateBook({
        bookName: cleanBook,
        ownershipField: cleanOwnership,
        balancingField: cleanBalance,
        teamUsers: cleanUsers,
        companyId: userProfile?.companyId || "",
      });
    } else {
      window.alert("Please fill out all fields");
    }
  };

  const handleUpdateBook = async (book: Partial<Book>) => {
    setLoading(true);
    try {
      const companyId = userProfile?.companyId; // Fetch the companyId from userProfile

      if (!companyId) {
        window.alert("Company ID not found. Unable to create book.");
        setLoading(false);
        return;
      }

      // Ensure all required fields are provided and not undefined
      const { bookName, ownershipField, balancingField, teamUsers } = book;

      if (!bookName || !ownershipField || !balancingField || !teamUsers) {
        window.alert("Missing required fields to create the book.");
        setLoading(false);
        return;
      }

      const config = { headers: { Authorization: `Bearer ${token}` } };

      // Add the companyId directly to the book object when creating it
      const createdBook = await bookService.createBook(
        { bookName, ownershipField, balancingField, teamUsers, companyId }, // Include companyId in the book object
        config // Pass the config separately
      );

      console.log("Book created successfully:", createdBook);
      navigate("/territory-live-state");
    } catch (error) {
      console.error("Failed to create book:", error);
      window.alert("Failed to create book. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        minHeight: "calc(100vh - 48px)",
        backgroundColor: "white",
        color: "white",
        borderRadius: "10px",
        marginBottom: "26px",
      }}
    >
      {loading && (
        <Box
          sx={{
            width: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
          }}
        >
          <LinearProgress sx={{ backgroundColor: "white" }} />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "85%",
          gap: 3,
          marginTop: "0px",
          flexGrow: 1,
        }}
      >
        <Typography
          sx={{ fontSize: "24px", fontWeight: "500", marginTop: "-7px" }}
        >
          Territory Module Setup
        </Typography>

        {/* Rest of your form code */}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={handleCreateBookOfBusiness}
          variant="contained"
          sx={{
            width: "240px",
            marginTop: "20px",
            marginBottom: "40px",
          }}
        >
          Create Book of Business
        </Button>
      </Box>
    </Box>
  );
};

export default TerritoryViewSetupTwo;
