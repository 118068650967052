import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import PasswordForm from "./PasswordForm";
import { passwordService } from "../../services/PasswordService";

const PasswordComponent: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token") || "";

  useEffect(() => {
    if (!token) {
      console.log("No token found");
    }
  }, [token]);

  const handleResetPassword = async (
    newPassword: string,
    confirmPassword: string
  ) => {
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    try {
      if (!token) {
        throw new Error("No reset token provided.");
      }

      await passwordService.passwordReset(token, newPassword);

      alert("Password reset successfully! You will be redirected to login.");
      navigate("/auth");
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("Failed to reset the password. Please try again.");
    }
  };

  return <PasswordForm onResetPassword={handleResetPassword} />;
};

export default PasswordComponent;
