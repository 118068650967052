import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { Avatar, Divider, ListItemIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HomeIcon from "@mui/icons-material/Public";
import UsersIcon from "@mui/icons-material/Public";
import IntegrationIcon from "@mui/icons-material/Public";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import ProfileEditPopup from "../common/ProfileEditPopup";
import companyLogo from "../../assets/icons/company-logo.png";

const Logo = styled("img")({
  height: 20,
  margin: "16px 0",
});

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const { userProfile } = useAuth();
  const theme = useTheme();
  const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);

  const handleHome = () => {
    navigate("/");
  };

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
    if (index === 1) {
      // navigate("/territory-planning");
      navigate("/admin-csv-preview");
    }
    if (index === 2) {
      navigate("/user-management");
    }
    if (index === 3) {
      navigate("/integrations");
    }
  };

  const handleAvatarClick = () => {
    setIsProfilePopupOpen(true);
  };

  const handleCloseProfilePopup = () => {
    setIsProfilePopupOpen(false);
  };

  const firstName = userProfile?.firstName || "Private";
  const lastName = userProfile?.lastName || "Member";
  const avatar = userProfile?.avatar || null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Drawer
        variant="permanent"
        sx={{
          width: 260,
          height: "100%",
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& .MuiDrawer-paper": {
            position: "relative",
            width: 240,
            height: "100%",
            boxSizing: "border-box",
            color: "white",
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            borderRight: `1px solid ${theme.palette.gray.main}`,
          },
        }}
      >
        <Box>
          <Box
            onClick={handleHome}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              marginTop: "5px",
            }}
          >
            <Logo
              sx={{
                width: "27px",
                height: "auto",
                marginLeft: 2.5,
                marginBottom: 2.3,
              }}
              alt="Forgeworks Logo"
              src={companyLogo}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                fontSize: "22px",
                color: "black",
                marginLeft: 2,
                marginBottom: 0.5,
                opacity: 0.8,
              }}
            >
              Forgeworks.ai
            </Typography>
          </Box>
          <Divider
            sx={{
              width: "97%",
              backgroundColor: theme.palette.gray.main,
            }}
          />

          <Box sx={{ overflow: "auto" }}>
            <Typography
              display="block"
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                color: theme.palette.textGray.main,
                paddingLeft: 3.5,
                paddingTop: 3,
              }}
            >
              Applications
            </Typography>
            <List>
              <ListItem
                button
                onClick={() => handleListItemClick(1)}
                sx={{
                  marginTop: 0,
                  pl: 2,
                  borderRadius: "10px",
                  color: "white",
                  position: "relative",
                  width: "90%",
                  marginLeft: "10px",
                  backgroundColor:
                    selectedIndex === 1
                      ? theme.palette.orangeHighlight.main
                      : "transparent",
                  "&:hover": {
                    backgroundColor: theme.palette.orangeHighlight.main,
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      selectedIndex === 1 ? theme.palette.orange.main : "black",
                    minWidth: "unset",
                    marginRight: "10px",
                  }}
                >
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Revenue Analytics"
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "black",
                    },
                  }}
                />
              </ListItem>
            </List>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            paddingBottom: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <List sx={{ width: "100%" }}>
            <Typography
              display="block"
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                color: theme.palette.textGray.main,
                paddingLeft: 3.5,
                paddingTop: 3,
              }}
            >
              Admin
            </Typography>
            <ListItem
              button
              onClick={() => handleListItemClick(2)}
              sx={{
                marginTop: 1.5,
                pl: 2,
                borderRadius: "10px",
                color: "white",
                position: "relative",
                width: "90%",
                marginLeft: "10px",
                backgroundColor:
                  selectedIndex === 2
                    ? theme.palette.orangeHighlight.main
                    : "transparent",
                "&:hover": {
                  backgroundColor: theme.palette.orangeHighlight.main,
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    selectedIndex === 2 ? theme.palette.orange.main : "black",
                  minWidth: "unset",
                  marginRight: "10px",
                }}
              >
                <UsersIcon />
              </ListItemIcon>
              <ListItemText
                primary="Users"
                primaryTypographyProps={{
                  sx: {
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "black",
                  },
                }}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleListItemClick(3)}
              sx={{
                pl: 2,
                borderRadius: "10px",
                color: "white",
                position: "relative",
                width: "90%",
                marginLeft: "10px",
                backgroundColor:
                  selectedIndex === 3
                    ? theme.palette.orangeHighlight.main
                    : "transparent",
                "&:hover": {
                  backgroundColor: theme.palette.orangeHighlight.main,
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    selectedIndex === 3 ? theme.palette.orange.main : "black",
                  minWidth: "unset",
                  marginRight: "10px",
                }}
              >
                <IntegrationIcon />
              </ListItemIcon>
              <ListItemText
                primary="Integrations"
                primaryTypographyProps={{
                  sx: {
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "black",
                  },
                }}
              />
            </ListItem>
            <ListItem
              sx={{
                borderRadius: "10px",
                color: "white",
                position: "relative",
                width: "70%",
                marginLeft: "15%",
              }}
            ></ListItem>
          </List>
          <Divider
            sx={{
              width: "97%",
              backgroundColor: theme.palette.gray.main,
            }}
          />
          <Box
            sx={{
              p: 0,
              marginTop: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "95%",
              cursor: "pointer",
            }}
            onClick={handleAvatarClick}
          >
            <Avatar
              alt=""
              src={avatar ? avatar : ""}
              sx={{
                marginRight: "auto",
                marginLeft: 2,
              }}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{ flexGrow: 1 }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "600",
                  fontSize: "15px",
                  color: "black",
                  marginRight: "auto",
                  marginLeft: 1.5,
                }}
              >
                {firstName} {lastName}
              </Typography>
            </Box>
            <ArrowForwardIosIcon
              sx={{
                marginRight: 2,
                color: "black",
                width: "10px",
                height: "10px",
              }}
            />
          </Box>
        </Box>
      </Drawer>

      {/* Profile Edit Popup */}
      <ProfileEditPopup
        open={isProfilePopupOpen}
        onClose={handleCloseProfilePopup}
      />
    </Box>
  );
};

export default Sidebar;
