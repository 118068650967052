import React, { useState, useRef } from "react";
import { Box, Button, LinearProgress } from "@mui/material";
import { httpService } from "../../services/HttpServices";

interface FileUpload {
  file: File;
  preview: string;
}

const CsvEmailPreviewer: React.FC = () => {
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [instruction, setInstruction] = useState("");
  const [email, setEmail] = useState("");
  const [previewHtml, setPreviewHtml] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    const fileUploads: FileUpload[] = selectedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setFiles([...files, ...fileUploads]);
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);

    if (updatedFiles.length === 0 && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handlePreview = async () => {
    if (files.length === 0 || !instruction) {
      setErrorMessage(
        "Please upload at least one CSV file and provide instructions."
      );
      return;
    }
    setErrorMessage(null);
    setLoading(true);

    const formData = new FormData();
    files.forEach((fileUpload) => {
      formData.append("csvFiles", fileUpload.file);
    });
    formData.append("instruction", instruction);

    try {
      const response = await httpService.post<any, FormData>(
        "/csv/process-file",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setPreviewHtml(response.organizedTable);
    } catch (error) {
      setErrorMessage("Error generating preview. Please try again.");
      console.error("Error generating preview:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendEmail = async () => {
    if (!previewHtml || !email) {
      setErrorMessage(
        "Please generate a preview and provide an email address."
      );
      return;
    }
    setErrorMessage(null);
    setLoading(true);

    const formData = new FormData();
    files.forEach((fileUpload) => {
      formData.append("csvFiles", fileUpload.file);
    });
    formData.append("instruction", instruction);
    formData.append("email", email);
    formData.append("scheduleType", "once");

    try {
      await httpService.post<any, FormData>(
        "/csv/process-file-email",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      alert("Email successfully sent!");
    } catch (error) {
      setErrorMessage("Error sending email. Please try again.");
      console.error("Error sending email:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      {loading && (
        <Box sx={{ width: "100%", marginBottom: "20px" }}>
          <LinearProgress />
        </Box>
      )}

      <h1>CSV Email Previewer</h1>
      <div style={{ marginBottom: "20px" }}>
        <Button
          variant="outlined"
          component="label"
          disabled={files.length >= 10}
        >
          Choose Files
          <input
            ref={fileInputRef}
            type="file"
            multiple
            accept=".csv"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </Button>
      </div>
      {files.length > 0 && (
        <div style={{ marginBottom: "20px" }}>
          <h3>Uploaded Files:</h3>
          <ul style={{ padding: 0, listStyle: "none" }}>
            {files.map((fileUpload, index) => (
              <li key={index}>
                {fileUpload.file.name}{" "}
                <Button
                  sx={{ marginLeft: "10px" }}
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={() => handleRemoveFile(index)}
                >
                  Remove
                </Button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div style={{ marginBottom: "20px" }}>
        <label>Instruction:</label>
        <textarea
          value={instruction}
          onChange={(e) => setInstruction(e.target.value)}
          style={{
            width: "100%",
            padding: "8px",
            marginTop: "10px",
            height: "150px",
            resize: "vertical",
            overflowY: "auto",
          }}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <Button variant="outlined" onClick={handlePreview} disabled={loading}>
          Preview
        </Button>
      </div>
      {previewHtml && (
        <div
          style={{
            marginBottom: "20px",
            border: "1px solid #ccc",
            padding: "10px",
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <h3>Email Preview:</h3>
          <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
        </div>
      )}
      <div style={{ marginBottom: "20px" }}>
        <label>Email Address:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ width: "100%", padding: "8px", marginTop: "10px" }}
        />
      </div>
      <div>
        <Button
          variant="contained"
          onClick={handleSendEmail}
          disabled={loading}
        >
          Send Email
        </Button>
      </div>
      {errorMessage && (
        <div style={{ color: "red", marginTop: "20px" }}>{errorMessage}</div>
      )}
    </div>
  );
};

export default CsvEmailPreviewer;
