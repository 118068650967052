import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Box, LinearProgress } from "@mui/material";
import { useAuth } from "../../context/AuthContext";

const ProtectedRoute: React.FC = () => {
  const { isLoggedIn, loading } = useAuth();

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 9999,
          backgroundColor: "white",
        }}
      >
        <LinearProgress sx={{ backgroundColor: "white" }} />
      </Box>
    );
  }

  return isLoggedIn ? <Outlet /> : <Navigate to="/auth" />;
};

export default ProtectedRoute;
