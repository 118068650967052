import { Box } from "@mui/material";
import React from "react";
import exampleImage from "../assets/icons/company-logo.png";

const Home: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        color: "black",
      }}
    >
      <img
        src={exampleImage}
        alt="CompanyLogo"
        style={{ maxWidth: "70px", maxHeight: "70px", marginBottom: "60px" }}
      />
    </Box>
  );
};

export default Home;
