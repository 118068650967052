import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF7432",
    },
    secondary: {
      main: "#4ABDE8",
    },
    background: {
      default: "#FFFFFF",
    },
    gray: {
      main: "#CCCCCC",
    },
    orange: {
      main: "#FF7432",
    },
    orangeHighlight: {
      main: "#FFF3EB",
    },
    blue: {
      main: "#4ABDE8",
    },
    textGray: {
      main: "#B0AEAE",
    },
    text: {
      primary: "#000000",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    h4: {
      fontSize: "2.0rem",
      fontWeight: 600,
      lineHeight: 1.0,
      textAlign: "center",
      color: "#000000",
    },
    subtitle1: {
      fontSize: "1.2rem",
      fontWeight: 400,
      lineHeight: 1.0,
      textAlign: "center",
      color: "#000000",
    },
    caption: {
      fontSize: "1.0rem",
      fontWeight: 400,
      lineHeight: 1.0,
      textAlign: "center",
      color: "#000000",
    },
    body1: {
      fontSize: "1.0rem",
      fontWeight: 400,
      lineHeight: 1.4,
      color: "#000000",
    },
    button: {
      textTransform: "none",
      fontWeight: "500",
      letterSpacing: "0.5px",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
          boxShadow: "none",
          fontWeight: "500",
          "&.MuiButton-sizeSmall": {
            height: "26px",
            fontSize: "11px",
            padding: "3px 9px",
          },
          "&.MuiButton-sizeMedium": {
            height: "40px",
            fontSize: "16px",
            padding: "6px 16px",
          },
          "&.MuiButton-sizeLarge": {
            height: "48px",
            fontSize: "18px",
            padding: "8px 20px",
          },
        },
        contained: {
          backgroundColor: "#FF7432",
          color: "white",
          "&:hover": {
            backgroundColor: "#FF7432",
          },
        },
        outlined: {
          backgroundColor: "transparent",
          color: "black",
          borderColor: "#B0AEAE",
          "&:hover": {
            backgroundColor: "#FFF3EB",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          height: "40px",
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: `2px solid #36454F`,
          },
        },
        underline: {
          "&:before": {
            borderBottom: `1px solid #0D0D0D`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#F5F5F5",
          width: "250px",
          height: "40px",
          borderRadius: "8px",
          cursor: "pointer",
          fontSize: "16px",
          fontWeight: "500",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#36454F",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#36454F",
          },
        },
        input: {
          "&::placeholder": {
            fontSize: "16px",
            fontWeight: "400",
            color: "black",
            opacity: 0.6,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#0D0D0D",
          color: "#36454F",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            height: "40px",
            width: "250px",
            padding: "0 2px",
            cursor: "pointer",
            "& .MuiAutocomplete-input": {
              padding: "5px 14px",
            },
            border: "none",
            boxShadow: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#F5F5F5",
            borderRadius: "8px",
            cursor: "pointer",
          },
          "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input":
            {
              padding: "5px 14px",
            },
        },
        paper: {
          marginTop: "8px",
          boxShadow: "none",
          borderRadius: "8px",
          border: "1px solid #E0E0E0",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "#F5F5F5",
          borderRadius: "8px",
          height: "40px",
          width: "250px",
          cursor: "pointer",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "black",
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          marginTop: "4px",
          boxShadow: "none",
          borderRadius: "8px",
          border: "1px solid #E0E0E0",
        },
      },
    },
  },
});

export default theme;
