import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import RegisterForm from "./RegisterForm";
import { userService, User } from "../../services/UserService";
import { useAuth } from "../../context/AuthContext";

const RegisterComponent: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const companyName = searchParams.get("companyName") || "";
  const token = searchParams.get("token") || "";
  const { loginFromRegistration, fetchUserProfile, setUserProfile } = useAuth();

  useEffect(() => {
    if (!companyName || !token) {
      navigate("/auth");
    }
  }, [companyName, token, navigate]);

  // Example of updating user profile after an update
  type UserProfileUpdate = Partial<User>;

  const updateUserProfile = async (updatedFields: UserProfileUpdate) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };

      // Call the user service to update the profile with the provided fields
      const updatedUser = await userService.updateUserProfile(
        updatedFields,
        config
      );

      // Update the user profile in the context or state
      setUserProfile(updatedUser);

      console.log("✅ User profile updated successfully");
    } catch (error) {
      console.error("Error updating user profile", error);
    }
  };

  const handleRegister = async (
    firstName: string,
    lastName: string,
    password: string
  ) => {
    try {
      console.log(
        "REACT_APP_BACKEND_URL which should be from HEROKU: ",
        process.env.REACT_APP_BACKEND_URL
      );
      const response = await userService.registerUser({
        firstName,
        lastName,
        password,
        token,
      });

      if (response.token) {
        console.log(
          "✅ Company registration success with token: ",
          response.token
        );
        // Update the users User object with First and Last names
        await updateUserProfile({ firstName: firstName, lastName: lastName });

        // Set the token in local storage
        loginFromRegistration(response.token);
        console.log("✅ User login success: ", response.token);

        // Fetch the users profile
        fetchUserProfile(response.token);
        console.log("✅ User profile fetch");

        navigate("/auth"); // This should see auth and make the correct routing
        console.log("✅ Navigated back to Auth route");
      } else {
        console.log("❌ Token is undefined");
      }
    } catch (error) {
      console.error("Error registering user", error);
    }
  };

  return <RegisterForm onRegister={handleRegister} companyName={companyName} />;
};

export default RegisterComponent;
