import React from "react";
import { Box, Button, Typography, Paper } from "@mui/material";

const Integrations: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "calc(100vh - 48px)",
        backgroundColor: "white",
        color: "black",
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          width: "100%",
          fontSize: "36px",
          fontWeight: "600",
          marginTop: "50px",
          color: "black",
        }}
      >
        Integrations
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "18px",
          fontWeight: "500",
          marginTop: "10px",
          color: "orange",
        }}
      >
        Coming Soon
      </Typography>

      {/* Salesforce Integration */}
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "80px",
          width: "40%",
          minWidth: "320px",
          maxWidth: "420px",
          padding: "20px",
          borderRadius: "10px",
          backgroundColor: "#fafafa",
        }}
      >
        <Box sx={{ textAlign: "center", flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: "black" }}>
            Salesforce
          </Typography>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Button
            variant="outlined"
            disabled
            sx={{
              color: "orange",
              borderColor: "orange",
              "&:hover": {
                borderColor: "darkorange",
                color: "darkorange",
              },
            }}
          >
            Coming Soon
          </Button>
        </Box>
      </Paper>

      {/* Hubspot Integration */}
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
          width: "40%",
          minWidth: "320px",
          maxWidth: "420px",
          padding: "20px",
          borderRadius: "10px",
          backgroundColor: "#fafafa",
        }}
      >
        <Box sx={{ textAlign: "center", flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: "black" }}>
            Hubspot
          </Typography>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Button
            variant="outlined"
            disabled
            sx={{
              color: "orange",
              borderColor: "orange",
              "&:hover": {
                borderColor: "darkorange",
                color: "darkorange",
              },
            }}
          >
            Coming Soon
          </Button>
        </Box>
      </Paper>

      <Typography
        sx={{
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "400",
          marginTop: "30px",
          color: "gray",
        }}
      >
        We're working hard to bring you these integrations. Stay tuned!
      </Typography>
    </Box>
  );
};

export default Integrations;
