import { AxiosRequestConfig } from "axios";
import { httpService } from "./HttpServices";

export class PasswordService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = "/password/";
  }

  // Request password reset email
  async requestPasswordReset(
    email: string,
    config?: AxiosRequestConfig
  ): Promise<void> {
    try {
      const response = await httpService.post(
        `${this.baseUrl}request`,
        { email },
        config
      );
      console.log("Password reset email sent:", response);
    } catch (error) {
      console.error("Failed to send password reset email:", error);
      throw error;
    }
  }

  // Handle password reset with token and new password
  async passwordReset(
    token: string,
    newPassword: string,
    config?: AxiosRequestConfig
  ): Promise<void> {
    try {
      const response = await httpService.post(
        `${this.baseUrl}reset`,
        { token, newPassword },
        config
      );
      console.log("Password reset successfully:", response);
    } catch (error) {
      console.error("Failed to reset password:", error);
      throw error;
    }
  }

  // Generic error handling method
  private handleError(error: any): void {
    console.error("API call failed:", error);
  }
}

// Export Singleton
export const passwordService = new PasswordService();
