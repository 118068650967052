import { Box, Button, LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { bookService } from "../../services/BookService";
import { useAuth } from "../../context/AuthContext";

const TerritorySetupOne: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [books, setBooks] = React.useState<boolean>(false);
  const userProfile = useAuth().userProfile;
  const navigate = useNavigate();
  const handleTerritoryBuilder = () => {
    navigate("/territory-builder");
  };

  const handleLiveState = () => {
    navigate("/territory-live-state");
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const companyId = userProfile?.companyId;

        if (!companyId) {
          console.error("No company ID found, skipping fetch.");
          setLoading(false);
          return;
        }

        const response = await bookService.getBooks(companyId);
        console.log("Response", response.data);

        if (response.data.length > 0) {
          setBooks(true);
        } else {
          setBooks(false);
        }

        setLoading(false);
      } catch (error) {
        console.log("Error", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userProfile]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "calc(100vh - 48px)",
        backgroundColor: "white",
        color: "white",
        borderRadius: "10px",
        gap: 3,
      }}
    >
      {!loading && (
        <Button onClick={handleTerritoryBuilder} variant="contained">
          Setup Module
        </Button>
      )}

      {books && (
        <Button onClick={handleLiveState} variant="contained">
          Live State
        </Button>
      )}

      {loading && (
        <Box
          sx={{
            width: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
          }}
        >
          <LinearProgress sx={{ backgroundColor: "white" }} />
        </Box>
      )}
    </Box>
  );
};

export default TerritorySetupOne;
