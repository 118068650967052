import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/Home";
import Territory from "./components/territoryPlanning/TerritoryViewSetupOne";
import TerritoryTwo from "./components/territoryPlanning/TerritoryViewSetupTwo";
import TerritoryLiveState from "./components/territoryPlanning/TerritoryLiveState";
import Integrations from "./components/Integrations";
import UserManager from "./components/UserManager";
import AuthComponent from "./components/common/AuthComponent";
import RegisterComponent from "./components/common/RegisterComponent";
import Layout from "./components/Layout";
import FullScreenLayout from "./components/FullScreenLayout";
import MobileMessage from "./components/common/MobileMessage";
import ProtectedRoute from "./components/common/ProtectedRoute";
import useScreenSize from "./hooks/UseScreenSize";
import PasswordComponent from "./components/common/PasswordComponent";
import LogViewer from "./components/common/LogViewer";
import CsvEmailPreviewer from "./components/common/CsvEmailPreviewer";

const AppRoutes: React.FC = () => {
  const isMobile = useScreenSize();

  if (isMobile) {
    return <MobileMessage />;
  }

  return (
    <Routes>
      <Route path="/auth" element={<AuthComponent />} />
      <Route path="/register" element={<RegisterComponent />} />
      <Route path="/password-form" element={<PasswordComponent />} />
      <Route
        path="/admin-csv-preview"
        element={
          <Layout>
            <CsvEmailPreviewer />
          </Layout>
        }
      />
      <Route
        path="/backend-logs"
        element={
          <FullScreenLayout>
            <LogViewer />
          </FullScreenLayout>
        }
      />

      <Route element={<ProtectedRoute />}>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/territory-planning"
          element={
            <Layout>
              <Territory />
            </Layout>
          }
        />
        <Route
          path="/territory-live-state"
          element={
            <Layout>
              <TerritoryLiveState />
            </Layout>
          }
        />
        <Route
          path="/territory-builder"
          element={
            <Layout>
              <TerritoryTwo />
            </Layout>
          }
        />
        <Route
          path="/integrations"
          element={
            <Layout>
              <Integrations />
            </Layout>
          }
        />
        <Route
          path="/user-management"
          element={
            <Layout>
              <UserManager />
            </Layout>
          }
        />
        {/* Logs Route */}
        <Route path="*" element={<Navigate to="/auth" />} />{" "}
        {/* Catch-all route */}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
