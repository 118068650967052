import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  Theme,
  Avatar,
  Select,
  SelectChangeEvent,
  MenuItem,
  LinearProgress,
} from "@mui/material";
import React, { useEffect } from "react";
import GroupIcon from "../../assets/icons/people-group-icon.png";
import CalendarTodayIcon from "../../assets/icons/calendar-check-icon.png";
import AttachMoneyIcon from "../../assets/icons/calendar-cancel-icon.png";
import PieChartIcon from "../../assets/icons/clock-icon.png";
import { bookService } from "../../services/BookService";
import { useNavigate } from "react-router-dom";
import TerritoryTable from "./TerritoryTable";
import { useAuth } from "../../context/AuthContext";

const TerritoryLiveState: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [book, setBook] = React.useState<string>("");
  const [serverBooks, setServerBooks] = React.useState<string[]>([]);
  const [selectedTab, setSelectedTab] = React.useState<string>("Summary");
  const { token, userProfile } = useAuth();

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const companyId = userProfile?.companyId;

        if (!companyId) {
          throw new Error("Company ID not found");
        }

        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await bookService.getBookNames(companyId, config);
        console.log("Response", response);

        // Extract the data array from the response
        if (response && response.data && Array.isArray(response.data)) {
          setServerBooks(response.data);
          if (response.data.length > 0) {
            const lastValue = response.data[response.data.length - 1];
            setBook(lastValue);
          }
        } else {
          console.error("Unexpected response data format", response);
        }

        setLoading(false);
      } catch (error) {
        console.log("Error", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userProfile?.companyId, token]);

  const handleBookChange = (event: SelectChangeEvent<string>) => {
    setBook(event.target.value);
  };

  const handleSetupModule = () => {
    navigate("/territory-planning");
  };

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "calc(100vh - 48px)",
        backgroundColor: "white",
        color: "white",
        borderRadius: "10px",
        marginBottom: "26px",
      }}
    >
      <Typography
        sx={{
          textAlign: "left",
          width: "90%",
          fontSize: "24px",
          marginTop: "-7px",
        }}
      >
        Books of Business
      </Typography>
      <Typography
        sx={{
          textAlign: "left",
          width: "90%",
          fontSize: "16px",
          marginTop: "20px",
        }}
      >
        Select Team
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: isSmallScreen ? "center" : "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={isSmallScreen ? "column" : "row"}
          gap={isSmallScreen ? "10px" : "93px"}
          sx={{
            marginTop: "5px",
            width: "90%",
            backgroundColor: "white",
            marginLeft: isSmallScreen ? "0" : "0px",
            alignItems: isSmallScreen ? "center" : "flex-start",
            justifyContent: isSmallScreen ? "center" : "space-between",
          }}
        >
          <Select
            value={book}
            onChange={handleBookChange}
            sx={{
              border: "1px solid #B0AEAE",
              width: "200px",
              height: "33px",
              "& .MuiSelect-select": {
                height: "100%",
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            {serverBooks.map((bookName, index) => (
              <MenuItem key={index} value={bookName}>
                {bookName}
              </MenuItem>
            ))}
          </Select>

          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button sx={{ width: "173px" }} variant="outlined">
              Edit Fields
            </Button>

            <Button onClick={handleSetupModule} variant="contained">
              Scenario Builder
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "0px",
            width: "90%",
            alignItems: "center",
            marginTop: "15px",
            marginLeft: "-10px",
          }}
        >
          <Button
            sx={{
              color: selectedTab === "Summary" ? "black" : "grey",
              backgroundColor: "transparent",
              boxShadow: "none",
              fontSize: "20px",
              fontWeight: "500",
              width: "auto",
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
            onClick={() => handleTabChange("Summary")}
            variant="contained"
          >
            Summary
          </Button>

          <Button
            sx={{
              color: selectedTab === "Companies" ? "black" : "grey",
              backgroundColor: "transparent",
              boxShadow: "none",
              fontSize: "20px",
              fontWeight: "500",
              width: "auto",
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
            onClick={() => handleTabChange("Companies")}
            variant="contained"
          >
            Companies
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Grid
            container
            sx={{
              marginTop: "10px",
              width: "100%",
              flexGrow: 1,
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Grid item>
              <Box
                sx={{
                  backgroundColor: "#4ABDE8",
                  borderRadius: "10px",
                  padding: "10px 35px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                }}
              >
                <Avatar
                  alt="Brantley Pace"
                  src={GroupIcon}
                  sx={{ marginRight: 1 }}
                />

                <Box marginLeft={2}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "26px",
                      fontWeight: "600",
                      color: "white",
                    }}
                  >
                    AMER
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      opacity: "0.8",
                      textAlign: "left",
                      fontSize: "11px",
                      fontWeight: "400",
                    }}
                  >
                    Territory
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  backgroundColor: "#4ABDE8",
                  borderRadius: "10px",
                  padding: "10px 35px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                }}
              >
                <Avatar
                  alt="Brantley Pace"
                  src={CalendarTodayIcon}
                  sx={{ marginRight: 1 }}
                />

                <Box marginLeft={2}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "26px",
                      fontWeight: "600",
                      color: "white",
                    }}
                  >
                    10
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      opacity: "0.8",
                      textAlign: "left",
                      fontSize: "11px",
                      fontWeight: "400",
                    }}
                  >
                    Avg Per User
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  backgroundColor: "#4ABDE8",
                  borderRadius: "10px",
                  padding: "10px 35px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                }}
              >
                <Avatar
                  alt="Brantley Pace"
                  src={AttachMoneyIcon}
                  sx={{ marginRight: 1 }}
                />
                <Box marginLeft={2}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "26px",
                      fontWeight: "600",
                      color: "white",
                    }}
                  >
                    ARR
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      opacity: "0.8",
                      textAlign: "left",
                      fontSize: "11px",
                      fontWeight: "400",
                    }}
                  >
                    Primary Variable
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  background:
                    "linear-gradient(to right, #FF414B 70%, #FF7A81 100%)",
                  borderRadius: "10px",
                  padding: "10px 35px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                }}
              >
                <Avatar
                  alt="Brantley Pace"
                  src={PieChartIcon}
                  sx={{ marginRight: 1 }}
                />
                <Box marginLeft={2}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "26px",
                      fontWeight: "600",
                      color: "white",
                    }}
                  >
                    52%
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      opacity: "0.8",
                      textAlign: "left",
                      fontSize: "11px",
                      fontWeight: "400",
                    }}
                  >
                    Book Score
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <TerritoryTable />
        </Box>
      </Box>
      {loading && (
        <Box
          sx={{
            width: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
          }}
        >
          <LinearProgress sx={{ backgroundColor: "white" }} />
        </Box>
      )}
    </Box>
  );
};

export default TerritoryLiveState;
