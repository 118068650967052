import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Select,
  MenuItem,
  LinearProgress,
} from "@mui/material";
import { useAuth } from "../context/AuthContext";
import { userService, User } from "../services/UserService";

const UserManager: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const { token, userProfile } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAllUsersPerCompany = useCallback(async () => {
    if (!token || !userProfile?.companyId) {
      console.log("No token or companyId, skipping fetch");
      return;
    }

    const companyId = userProfile.companyId;
    const config = { headers: { Authorization: `Bearer ${token}` } };
    console.log("Fetching users for company:", companyId);
    console.log("Config:", config);
    setLoading(true);

    try {
      const users = await userService.getUsersByCompanyId(companyId, config);
      console.log("Fetched users:", users);
      setUsers(users);
    } catch (error) {
      console.log("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  }, [token, userProfile?.companyId]);

  useEffect(() => {
    console.log("UserManager component mounted or token/companyId changed");
    fetchAllUsersPerCompany();
  }, [fetchAllUsersPerCompany]);

  return (
    <>
      {loading && (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
            backgroundColor: "white",
          }}
        >
          <LinearProgress sx={{ backgroundColor: "white" }} />
        </Box>
      )}

      {!loading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            minHeight: "calc(100vh - 48px)",
            color: "black",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <Typography
            sx={{
              textAlign: "left",
              width: "100%",
              fontSize: "24px",
              fontWeight: "400",
              marginTop: "-30px",
            }}
          >
            Users
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <Button variant="contained" disabled>
              Invite New User
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "50px",
            }}
          >
            <TableContainer
              sx={{
                width: "90%",
                padding: "20px",
                borderRadius: "10px",
                border: "1px solid #8282",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td": { borderBottom: "none" },
                      }}
                    >
                      <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        {user.isAdmin ? "Admin" : user.role}
                      </TableCell>
                      <TableCell align="right">
                        <Select
                          value=""
                          disabled
                          sx={{ minWidth: "120px" }}
                          displayEmpty
                        >
                          <MenuItem value="">
                            <em>Admin Lock</em>
                          </MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </>
  );
};

export default UserManager;
