import { AxiosRequestConfig } from "axios";
import { httpService } from "./HttpServices";
export interface HubSpotUser {
  firstName: string;
  lastName: string;
  createdAt: string;
  archived: boolean;
  id: string;
  userId: number;
  email: string;
  updatedAt: string;
}

export interface HubSpotCompanyProperty {
  createdAt: string;
  name: string;
  label: string;
}

export interface ApiResponse<T> {
  data: T;
  message: string;
  status: number;
}

class HubSpotService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = "/hubspot/";
  }

  async getUsers(
    config?: AxiosRequestConfig
  ): Promise<ApiResponse<HubSpotUser[]>> {
    try {
      return await httpService.get<ApiResponse<HubSpotUser[]>>(
        `${this.baseUrl}users`,
        config
      );
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  async getCompanyProperties(
    config?: AxiosRequestConfig
  ): Promise<ApiResponse<HubSpotCompanyProperty[]>> {
    try {
      return await httpService.get<ApiResponse<HubSpotCompanyProperty[]>>(
        `${this.baseUrl}companies/property-names`,
        config
      );
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  private handleError(error: any): void {
    console.error("API call failed:", error);
  }
}

export const hubSpotService = new HubSpotService();
