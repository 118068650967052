import React from "react";
import { Box, Typography } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import theme from "../../styles/Theme";

const MobileMessage: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "black",
          color: "#000",
          padding: "20px",
          textAlign: "center",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            background:
              "linear-gradient(90deg, #FF7432, #FF7432,#FF7432, black)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          FORGEWORKS
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Inter",
            fontWeight: "500",
            fontSize: "24px",
            textAlign: "center",
            lineHeight: 1.5,
            color: "grey",
          }}
        >
          Sorry for the inconvenience! <br />
          Please use a desktop device <br />
          to access this site.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: "300",
            fontSize: "18px",
            textAlign: "center",
            lineHeight: 1.5,
            color: "grey",
          }}
        >
          "Ahoy, matey! The treasures of this site <br />
          be best viewed on a desktop. Set sail there!"
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default MobileMessage;
