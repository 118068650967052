import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  OutlinedInput,
  Box,
  Typography,
} from "@mui/material";

interface ForgotPasswordDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (email: string) => void;
}

const ForgotPasswordDialog: React.FC<ForgotPasswordDialogProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [email, setEmail] = React.useState("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(email);
    setEmail("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography>
            Please enter your email address. If an account is associated with
            this email, you will receive instructions to reset your password.
          </Typography>
          <OutlinedInput
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            sx={{
              width: "80%",
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
