import React, { useState } from "react";
import {
  Box,
  Button,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  CheckCircle,
  Cancel,
} from "@mui/icons-material";
import { ThemeProvider } from "@emotion/react";
import theme from "../../styles/Theme";

interface PasswordFormProps {
  onResetPassword: (newPassword: string, confirmPassword: string) => void;
}

const PasswordForm: React.FC<PasswordFormProps> = ({ onResetPassword }) => {
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onResetPassword(newPassword, confirmPassword);
  };

  const passwordsMatch = newPassword === confirmPassword && newPassword !== "";

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "white",
          zIndex: 1300,
        }}
      >
        <Typography
          sx={{
            fontSize: isSmallScreen ? "48px" : "48px",
            fontWeight: "bold",
            textAlign: "center",
            background:
              "linear-gradient(90deg, #FF7432, #FF7432,#FF7432, black)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            marginBottom: isSmallScreen ? "20px" : "20px",
          }}
        >
          FORGEWORKS
        </Typography>
        <form
          onSubmit={handleSubmit}
          style={{
            width: "100%",
            maxWidth: 400,
            minWidth: 400,
            backgroundColor: "white",
            padding: 40,
            borderRadius: "10px",
            boxShadow: "0 0 50px rgba(0, 0, 0, 0.1)",
            paddingBottom: 30,
            marginBottom: 40,
          }}
        >
          {/* Hidden username field for accessibility - avoid dev tools warning */}
          <input
            type="text"
            name="username"
            defaultValue=""
            style={{ display: "none" }}
            aria-hidden="true"
            autoComplete="username"
          />

          <Typography
            sx={{
              mb: 0,
              fontWeight: "600",
              fontSize: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              flexWrap: "wrap",
            }}
          >
            Reset Your Password! 🔑
          </Typography>
          <Typography
            sx={{
              mt: 1.5,
              fontWeight: "300",
              fontSize: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            Please enter your new password twice to confirm. Let's get you back
            on track!
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 2,
              mt: 2,
            }}
          >
            <OutlinedInput
              name="newPassword"
              type={showPassword ? "text" : "password"}
              placeholder="New Password"
              autoComplete="new-password"
              fullWidth
              sx={{ mb: 2 }}
              value={newPassword}
              onChange={handlePasswordChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <OutlinedInput
              name="confirmPassword"
              type={showPassword ? "text" : "password"}
              placeholder="Confirm Password"
              autoComplete="new-password"
              fullWidth
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              endAdornment={
                <InputAdornment position="end">
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    {passwordsMatch ? (
                      <CheckCircle sx={{ color: "green" }} />
                    ) : (
                      confirmPassword && <Cancel sx={{ color: "red" }} />
                    )}
                  </Box>
                </InputAdornment>
              }
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                width: 250,
                marginTop: "15px",
                borderRadius: "3px",
                fontSize: "1rem",
                textTransform: "none",
              }}
              disabled={!passwordsMatch}
            >
              Reset Password
            </Button>
          </Box>
        </form>
      </Box>
    </ThemeProvider>
  );
};

export default PasswordForm;
