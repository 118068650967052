import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Avatar,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface Data {
  accountExecutives: string;
  numberOfAccounts: number;
  totalArr: number;
  bookScore: boolean;
}

const dummyData: Data[] = [
  {
    accountExecutives: "Brantley Pace",
    numberOfAccounts: 15,
    totalArr: 68,
    bookScore: true,
  },
  {
    accountExecutives: "Charlie Arcodia",
    numberOfAccounts: 20,
    totalArr: 100,
    bookScore: false,
  },
  {
    accountExecutives: "Kelley Ryder",
    numberOfAccounts: 35,
    totalArr: 99,
    bookScore: true,
  },
  {
    accountExecutives: "Cristina Ryder",
    numberOfAccounts: 35,
    totalArr: 99,
    bookScore: true,
  },
  {
    accountExecutives: "Brian Ryder",
    numberOfAccounts: 342,
    totalArr: 33,
    bookScore: true,
  },
  {
    accountExecutives: "Stanley Ryder",
    numberOfAccounts: 4535,
    totalArr: 454,
    bookScore: true,
  },
  {
    accountExecutives: "Steven Ryder",
    numberOfAccounts: 55,
    totalArr: 44,
    bookScore: false,
  },
  {
    accountExecutives: "Eric Ryder",
    numberOfAccounts: 35,
    totalArr: 99,
    bookScore: true,
  },
  {
    accountExecutives: "Doris Ryder",
    numberOfAccounts: 35,
    totalArr: 55,
    bookScore: true,
  },
  {
    accountExecutives: "Heartmen Ryder",
    numberOfAccounts: 87,
    totalArr: 66,
    bookScore: false,
  },
  {
    accountExecutives: "Jessie Ryder",
    numberOfAccounts: 35,
    totalArr: 99,
    bookScore: true,
  },
  {
    accountExecutives: "Sarah Ryder",
    numberOfAccounts: 35,
    totalArr: 99,
    bookScore: true,
  },
  {
    accountExecutives: "Regina Ryder",
    numberOfAccounts: 35,
    totalArr: 99,
    bookScore: false,
  },
  {
    accountExecutives: "Becky Ryder",
    numberOfAccounts: 35,
    totalArr: 99,
    bookScore: false,
  },
  {
    accountExecutives: "Martha Ryder",
    numberOfAccounts: 35,
    totalArr: 99,
    bookScore: true,
  },
];

const MyTable: React.FC = () => {
  return (
    <TableContainer
      component={Paper}
      style={{
        maxHeight: "calc(100vh - 200px)",
        overflow: "auto",
        width: "90%",
        marginTop: "20px",
        border: "1px solid #E0E0E0",
        padding: "15px",
        borderRadius: "10px",
        boxShadow: "none",
      }}
    >
      <Typography
        sx={{ marginLeft: "7px", fontSize: "16px", fontWeight: "500" }}
        gutterBottom
      >
        Account Executives Book of Business
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                color: "#828282",
                fontWeight: "500",
                fontSize: "16px",
                height: "10px",
                padding: "4px 8px",
              }}
            >
              Account Executives
            </TableCell>
            <TableCell
              sx={{
                color: "#828282",
                fontWeight: "500",
                fontSize: "16px",
                padding: "4px 8px",
              }}
            >
              # of Accounts
            </TableCell>
            <TableCell
              sx={{
                color: "#828282",
                fontWeight: "500",
                fontSize: "16px",
                padding: "4px 8px",
              }}
            >
              Total ARR
            </TableCell>
            <TableCell
              sx={{
                color: "#828282",
                fontWeight: "500",
                fontSize: "16px",
                padding: "4px 8px",
              }}
            >
              Book Score
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dummyData.map((row) => (
            <TableRow
              key={Math.random()}
              sx={{ height: "20px", minHeight: "20px", lineHeight: 1 }}
            >
              <TableCell sx={{ height: "20px", padding: "13px 8px" }}>
                {row.accountExecutives}
              </TableCell>
              <TableCell sx={{ height: "20px", padding: "13px 8px" }}>
                {row.numberOfAccounts}
              </TableCell>
              <TableCell sx={{ height: "20px", padding: "13px 8px" }}>
                {row.totalArr}
              </TableCell>
              <TableCell
                sx={{ height: "20px", padding: "13px 8px" }}
                align="center"
              >
                {row.bookScore ? (
                  <Avatar
                    style={{
                      height: "25px",
                      width: "25px",
                      backgroundColor: "green",
                    }}
                  >
                    <ArrowUpwardIcon
                      style={{
                        height: "15px",
                        width: "15px",
                      }}
                    />
                  </Avatar>
                ) : (
                  <Avatar
                    style={{
                      height: "25px",
                      width: "25px",
                      backgroundColor: "red",
                    }}
                  >
                    <ArrowDownwardIcon
                      style={{
                        height: "15px",
                        width: "15px",
                      }}
                    />
                  </Avatar>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MyTable;
