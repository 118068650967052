import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import { Box, LinearProgress } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { userService } from "../../services/UserService";
import { passwordService } from "../../services/PasswordService";

const AuthComponent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn, login } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  const handleLogin = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email") as string;
    const password = formData.get("password") as string;

    console.log("Login:", email, password);

    try {
      const response = await userService.loginUser({ email, password });
      console.log("Token response - ✅", response.accessToken);
      login(response.accessToken);
      navigate("/");
    } catch (error) {
      alert(`Login failed: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPasswordOpen = () => {
    setForgotPasswordOpen(true);
  };

  const handleForgotPasswordClose = () => {
    setForgotPasswordOpen(false);
  };

  const handleForgotPasswordSubmit = async (email: string) => {
    try {
      setLoading(true);
      console.log("Resetting password for email:", email);
      await passwordService.requestPasswordReset(email);
      alert("Password reset link sent! Check your email.");
    } catch (error) {
      alert(`Failed to send password reset email: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "white",
        zIndex: 1300,
      }}
    >
      <Box sx={{ width: 300 }}>
        {loading && (
          <Box
            sx={{
              width: "100%",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 9999,
            }}
          >
            <LinearProgress sx={{ backgroundColor: "white" }} />
          </Box>
        )}
        <LoginForm
          onLogin={handleLogin}
          onForgotPassword={handleForgotPasswordOpen}
        />
        <ForgotPasswordDialog
          open={forgotPasswordOpen}
          onClose={handleForgotPasswordClose}
          onSubmit={handleForgotPasswordSubmit}
        />
      </Box>
    </Box>
  );
};

export default AuthComponent;
