import React from "react";
import {
  Box,
  Button,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import theme from "../../styles/Theme";

interface LoginFormProps {
  onLogin: (event: React.FormEvent<HTMLFormElement>) => void;
  onForgotPassword: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin, onForgotPassword }) => {
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "white",
          zIndex: 1300,
        }}
      >
        <Typography
          sx={{
            fontSize: isSmallScreen ? "48px" : "48px",
            fontWeight: "bold",
            textAlign: "center",
            background:
              "linear-gradient(90deg, #FF7432, #FF7432,#FF7432, black)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            marginBottom: isSmallScreen ? "20px" : "20px",
          }}
        >
          FORGEWORKS
        </Typography>
        <form
          onSubmit={onLogin}
          style={{
            width: "100%",
            maxWidth: 350,
            minWidth: 300,
            backgroundColor: "white",
            padding: 30,
            borderRadius: "10px",
            boxShadow: "0 0 50px rgba(0, 0, 0, 0.1)",
            paddingBottom: 30,
            marginBottom: 40,
          }}
        >
          <Typography
            sx={{
              mb: 2,
              fontWeight: "400",
              fontSize: "24px",
              textAlign: "center",
            }}
          >
            Welcome <span style={{ fontWeight: "bold" }}>{"back"}</span>! 🎉
          </Typography>
          <Typography
            sx={{
              mb: 3,
              fontFamily: "Inter",
              fontWeight: "300",
              fontSize: "20px",
              textAlign: "center",
              lineHeight: 1.5,
              width: "100%",
            }}
          >
            Please provide your email and password to log in. Let's get you
            rolling!
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 2,
              mt: 2,
            }}
          >
            <OutlinedInput
              name="email"
              type="email"
              placeholder="Email"
              autoComplete="email"
              fullWidth
              sx={{ mb: 2 }}
            />
            <OutlinedInput
              name="password"
              type="password"
              placeholder="Password"
              autoComplete="current-password"
              fullWidth
            />
          </Box>

          {/* Button Row for Cancel and Submit */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              marginTop: 2,
            }}
          >
            {/* <Button
              type="button"
              variant="outlined"
              sx={{
                width: "50%",
                borderRadius: "3px",
                fontSize: "1rem",
                textTransform: "none",
              }}
            >
              Cancel
            </Button> */}
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: "50%",
                borderRadius: "3px",
                fontSize: "1rem",
                textTransform: "none",
              }}
            >
              Submit
            </Button>
          </Box>

          {/* Forgot Password Button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <Button
              variant="text"
              onClick={onForgotPassword}
              sx={{
                textTransform: "none",
              }}
            >
              Forgot Password?
            </Button>
          </Box>
        </form>
      </Box>
    </ThemeProvider>
  );
};

export default LoginForm;
