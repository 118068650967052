import { AxiosRequestConfig } from "axios";
import { httpService } from "./HttpServices";

// Define the User interface
export interface User {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  isAdmin: boolean;
  teamId?: string | null;
  companyId?: string;
  token?: string;
  avatar?: string;
  role?: string;
}

// Define the response structure for registering a user
export interface RegisterUserResponse {
  user: {
    id: string;
    email: string;
    role: string;
  };
  token: string;
}

// Define the interface for login response
export interface LoginUserResponse {
  accessToken: string;
  refreshToken: string;
}

// Define the interface for refresh token response
export interface RefreshTokenResponse {
  accessToken: string;
}

// Define a generic interface to handle API responses
export interface ApiResponse<T> {
  data: T;
  message: string;
  status: number;
}

// Define the UserService class
export class UserService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = "/users/";
  }

  async updateUserProfile(
    data: Partial<User>,
    config?: AxiosRequestConfig
  ): Promise<User> {
    try {
      const response = await httpService.patch<{ data: { user: User } }>(
        `${this.baseUrl}update-profile`,
        data,
        config
      );
      console.log("response:", response);
      console.log("API response user:", response.data.user);
      console.log("API response company id:", response.data.user.companyId);

      return response.data.user;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Method to register a user
  async registerUser(
    user: {
      firstName: string;
      lastName: string;
      password: string;
      token: string;
    },
    config?: AxiosRequestConfig
  ): Promise<RegisterUserResponse> {
    try {
      const response = await httpService.post<
        ApiResponse<RegisterUserResponse>,
        { firstName: string; lastName: string; password: string; token: string }
      >(`${this.baseUrl}register`, user, config);

      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Method to login a user
  async loginUser(
    user: Pick<User, "email" | "password">,
    config?: AxiosRequestConfig
  ): Promise<LoginUserResponse> {
    try {
      const response = await httpService.post<
        ApiResponse<LoginUserResponse>,
        Pick<User, "email" | "password">
      >(`${this.baseUrl}login`, user, config);

      console.log("Login response inside loginUser:", response);

      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Method to get the current logged-in user
  async getCurrentUser(config?: AxiosRequestConfig): Promise<User> {
    try {
      const response = await httpService.get<ApiResponse<{ user: User }>>(
        `${this.baseUrl}current`,
        config
      );
      const user = response.data.user;
      return user;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  async resetPassword(
    email: string,
    config?: AxiosRequestConfig
  ): Promise<void> {
    try {
      const response = await httpService.post(
        `${this.baseUrl}password/request`,
        { email },
        config
      );
      console.log("Password reset email sent:", response);
    } catch (error) {
      console.error("Failed to send password reset email:", error);
      throw error;
    }
  }

  async getUsersByCompanyId(
    companyId: string,
    config?: AxiosRequestConfig
  ): Promise<User[]> {
    try {
      const response = await httpService.get<ApiResponse<{ users: User[] }>>(
        `users/company/${companyId}/users`,
        config
      );
      const users = response.data.users;
      return users;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Method to refresh the authentication token
  async refreshToken(
    token: { refreshToken: string },
    config?: AxiosRequestConfig
  ): Promise<RefreshTokenResponse> {
    try {
      return await httpService.post<
        RefreshTokenResponse,
        { refreshToken: string }
      >("/tokens/refresh", token, config);
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Generic error handling method
  private handleError(error: any): void {
    // Handle error logging or display here
    console.error("API call failed:", error);
  }
}

// Export the user service as a singleton
export const userService = new UserService();
