import React from "react";
import Box from "@mui/material/Box";
import Sidebar from "./permanent/Sidebar";
import { useTheme } from "@mui/material/styles";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        display: "flex",
        height: "100vh",
      }}
    >
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: "30px",
          marginRight: "25px",
          overflow: "auto",
        }}
      >
        <Box sx={{ mt: 3 }}>{children}</Box>
      </Box>
    </Box>
  );
};

export default Layout;
