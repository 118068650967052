import React from "react";
import {
  Box,
  Button,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import theme from "../../styles/Theme";

interface RegisterFormProps {
  onRegister: (firstName: string, lastName: string, password: string) => void;
  companyName: string;
}

const RegisterForm: React.FC<RegisterFormProps> = ({
  onRegister,
  companyName,
}) => {
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const firstName = formData.get("firstName") as string;
    const lastName = formData.get("lastName") as string;
    const password = formData.get("password") as string;
    onRegister(firstName, lastName, password);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "white",
          zIndex: 1300,
        }}
      >
        <Typography
          sx={{
            fontSize: isSmallScreen ? "48px" : "48px",
            fontWeight: "bold",
            textAlign: "center",
            background:
              "linear-gradient(90deg, #FF7432, #FF7432,#FF7432, black)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            marginBottom: isSmallScreen ? "20px" : "20px",
          }}
        >
          FORGEWORKS
        </Typography>
        <form
          onSubmit={handleSubmit}
          style={{
            width: "100%",
            maxWidth: 400,
            minWidth: 400,
            backgroundColor: "white",
            padding: 40,
            borderRadius: "10px",
            boxShadow: "0 0 50px rgba(0, 0, 0, 0.1)",
            paddingBottom: 30,
            marginBottom: 40,
          }}
        >
          <Typography
            sx={{
              mb: 0,
              fontWeight: "400",
              fontSize: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              flexWrap: "wrap",
            }}
          >
            Welcome&nbsp;
            <span style={{ fontWeight: "bold", whiteSpace: "normal" }}>
              {companyName}
            </span>
            ! 🎉
          </Typography>
          <Typography
            sx={{
              mt: 1.5,
              fontFamily: "Inter",
              fontWeight: "300",
              fontSize: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            Get started by setting up your first name, last name, and password.
            Let’s get you all set up and ready to roll!
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 2,
              mt: 2,
            }}
          >
            <OutlinedInput
              name="firstName"
              type="text"
              placeholder="First Name"
              autoComplete="given-name"
              fullWidth
              sx={{ mb: 2 }}
            />
            <OutlinedInput
              name="lastName"
              type="text"
              placeholder="Last Name"
              autoComplete="family-name"
              fullWidth
              sx={{ mb: 2 }}
            />
            <OutlinedInput
              name="password"
              type="password"
              placeholder="Password"
              autoComplete="new-password"
              fullWidth
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                width: 250,
                marginTop: "15px",
                borderRadius: "3px",
                fontSize: "1rem",
                textTransform: "none",
              }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </ThemeProvider>
  );
};

export default RegisterForm;
