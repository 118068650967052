import React, { useEffect, useState } from "react";
import { httpService } from "../../services/HttpServices";
import { Box, CircularProgress, Typography } from "@mui/material";

const LogViewer: React.FC = () => {
  const [logs, setLogs] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchLogs = async () => {
    try {
      setLoading(true);
      console.log("Fetching logs from: ", process.env.REACT_APP_BACKEND_URL);
      const response = await httpService.get<{ data: { logs: string[] } }>(
        "/logs/stored-logs"
      );
      console.log("Response received:", response);

      if (response && response.data && Array.isArray(response.data.logs)) {
        console.log("Valid logs:", response.data.logs);
        setLogs(response.data.logs);
      } else {
        console.warn(
          "Logs are not an array or undefined. Setting empty array."
        );
        setLogs([]);
      }
      setLoading(false);
    } catch (err) {
      console.error("Failed to fetch logs:", err);
      setError("Failed to fetch logs.");
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("useEffect triggered");

    fetchLogs();

    const intervalId = setInterval(() => {
      console.log("Polling logs...");
      fetchLogs();
    }, 5000);

    return () => {
      console.log("Cleaning up the interval.");
      clearInterval(intervalId);
    };
  }, []);

  const highlightLog = (log: string) => {
    const highlighted = log
      .replace(/(GET|POST|PUT|DELETE|PATCH)/g, (match) => {
        return `<span style="color: blue; font-size: 18px; font-weight: bold;">${match}</span>`;
      })
      .replace(/status=(\d+)/g, (match, statusCode) => {
        const color = statusCode.startsWith("2")
          ? "green"
          : statusCode.startsWith("4") || statusCode.startsWith("5")
          ? "red"
          : "orange";
        return `<span style="color: ${color}; font-size: 18px; font-weight: bold;">${match}</span>`;
      })
      .replace(/path="([^"]+)"/g, (match, path) => {
        return `<span style="color: purple; font-weight: bold;">${match}</span>`;
      });

    return { __html: highlighted };
  };

  if (loading) {
    console.log("Loading state: Showing spinner");
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    console.error("Error state: ", error);
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  console.log("Rendering logs. Total logs:", logs?.length);

  return (
    <Box
      sx={{
        padding: "16px",
        backgroundColor: "#f5f5f5",
        height: "100vh",
        overflowY: "scroll",
        fontFamily: "monospace",
      }}
    >
      {Array.isArray(logs) && logs.length > 0 ? (
        logs.map((log, index) => {
          console.log(`Rendering log at index ${index}:`, log);
          return (
            <Box
              key={index}
              sx={{
                padding: "8px",
                marginBottom: "4px",
                backgroundColor: index % 2 === 0 ? "#e0e0e0" : "#fff",
                borderRadius: "4px",
                whiteSpace: "pre-wrap",
              }}
              dangerouslySetInnerHTML={highlightLog(log)}
            />
          );
        })
      ) : (
        <Typography variant="h6">No logs available</Typography>
      )}
    </Box>
  );
};

export default LogViewer;
