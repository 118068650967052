import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import { userService, User } from "../services/UserService";

interface AuthContextType {
  isLoggedIn: boolean;
  loading: boolean;
  token?: string;
  userProfile?: User;
  login: (token: string) => void;
  fetchUserProfile: (token: string) => void;
  fetchAllUsersPerCompany: (token: string) => void;
  loginFromRegistration: (token: string) => void;
  logout: () => void;
  setUserProfile: (profile: User) => void;
  refreshAuthToken: () => Promise<string | null>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [userProfile, setUserProfile] = useState<User | undefined>(undefined);

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    setToken(undefined);
    setIsLoggedIn(false);
    setUserProfile(undefined);
  }, []);

  const fetchUserProfile = useCallback(
    async (token: string) => {
      try {
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const user = await userService.getCurrentUser(config);

        // Set the user profile directly
        setUserProfile(user);
        console.log("✅ User profile fetched:", user);
      } catch (error) {
        console.error("An error occurred during user profile fetch:", error);
        logout();
      } finally {
        setLoading(false);
      }
    },
    [logout]
  );

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
      setIsLoggedIn(true);
      fetchUserProfile(storedToken);
      console.log("🔐 ", storedToken);
    } else {
      setLoading(false);
    }
  }, [fetchUserProfile]);

  const login = (token: string) => {
    localStorage.setItem("token", token);
    setToken(token);
    setIsLoggedIn(true);
    fetchUserProfile(token);
  };

  const loginFromRegistration = (token: string) => {
    localStorage.setItem("token", token);
    setToken(token);
    setIsLoggedIn(true);
  };

  const fetchAllUsersPerCompany = async (token: string) => {
    const companyId = userProfile?.companyId ?? "";
    const config = { headers: { Authorization: `Bearer ${token}` } };

    try {
      const users = await userService.getUsersByCompanyId(companyId, config);
      return users;
    } catch (error) {
      console.error("Failed to fetch users:", error);
      return null;
    }
  };

  const refreshAuthToken = useCallback(async (): Promise<string | null> => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      logout();
      return null;
    }

    try {
      const response = await userService.refreshToken({ refreshToken });
      if (response.accessToken) {
        localStorage.setItem("token", response.accessToken);
        setToken(response.accessToken);
        return response.accessToken;
      } else {
        logout();
        return null;
      }
    } catch (error) {
      console.error("An error occurred during token refresh:", error);
      logout();
      return null;
    }
  }, [logout]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        loading,
        token,
        userProfile,
        login,
        fetchUserProfile,
        fetchAllUsersPerCompany,
        loginFromRegistration,
        logout,
        setUserProfile,
        refreshAuthToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
