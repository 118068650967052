import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Avatar,
  OutlinedInput,
  LinearProgress,
  Typography,
  Container,
  Grid,
  Slide,
  Modal,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useAuth } from "../../context/AuthContext";
import { User } from "../../services/UserService";
import { userService } from "../../services/UserService";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileEditPopup: React.FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  const { userProfile, setUserProfile, token, logout } = useAuth();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [avatar, setAvatar] = useState<File | null>(null);
  const [preview, setPreview] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (userProfile) {
      setFirstName(userProfile.firstName || "");
      setLastName(userProfile.lastName || "");
      setPreview(userProfile.avatar || "");
    }
  }, [userProfile, open]);

  const updateUserProfile = async (updatedFields: Partial<User>) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const updatedUser = await userService.updateUserProfile(
        updatedFields,
        config
      );

      if (!updatedUser) {
        throw new Error(
          "Failed to update user profile; received undefined response."
        );
      }

      setUserProfile(updatedUser);
    } catch (error) {
      console.error("Error updating user profile", error);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const updatedProfile: Partial<User> = {};

    if (firstName && firstName !== userProfile?.firstName) {
      updatedProfile.firstName = firstName;
    }
    if (lastName && lastName !== userProfile?.lastName) {
      updatedProfile.lastName = lastName;
    }

    if (avatar) {
      const formData = new FormData();
      formData.append("file", avatar);
      formData.append("upload_preset", "ml_default");

      try {
        const response = await fetch(
          `https://api.cloudinary.com/v1_1/djruww4f1/image/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();
          const imageUrl = data.secure_url;
          updatedProfile.avatar = imageUrl;
        } else {
          console.error("Failed upload:", response.statusText);
          return;
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        setLoading(false);
        return;
      }
    }

    if (Object.keys(updatedProfile).length > 0) {
      await updateUserProfile(updatedProfile);
    }

    setLoading(false);
    onClose();
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setAvatar(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const openLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };

  const closeLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  const handleLogout = () => {
    logout();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
      transitionDuration={{ enter: 300, exit: 300 }}
    >
      {loading && (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
            backgroundColor: "transparent",
          }}
        >
          <LinearProgress sx={{ backgroundColor: "white" }} />
        </Box>
      )}
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.2)", // Slight opacity background
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent={"center"}
            sx={{ minHeight: "500px", width: "100%" }}
          >
            {/* Left Side - Avatar and Info */}
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                backgroundColor: "rgba(245, 245, 245, 0.9)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 4,
                borderRadius: {
                  xs: "16px 16px 0 0",
                  md: "16px 0 0 16px",
                },
                marginLeft: {
                  xs: "25px",
                  md: "0",
                },
                textAlign: "center",
              }}
            >
              <Typography variant="h5" sx={{ mb: 3, fontWeight: 600 }}>
                Profile Picture
              </Typography>

              <input
                ref={fileInputRef}
                accept="image/*"
                style={{ display: "none" }}
                id="profile-photo-upload"
                type="file"
                onChange={handleAvatarChange}
              />
              <Avatar
                src={preview}
                sx={{
                  width: 140,
                  height: 140,
                  mb: 3,
                  cursor: "pointer",
                  boxShadow: 4,
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
                onClick={handleAvatarClick}
              />
            </Grid>

            {/* Right Side - Form Fields */}
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: 4,
                borderRadius: {
                  xs: "0 0 16px 16px", // Rounded corners on small screens
                  md: "0 16px 16px 0", // Rounded right corners on larger screens
                },
              }}
            >
              <Typography variant="h5" sx={{ mb: 3, fontWeight: 600 }}>
                Edit Your Details
              </Typography>

              <OutlinedInput
                fullWidth
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                margin="dense"
                sx={{ mb: 3 }}
              />
              <OutlinedInput
                fullWidth
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                margin="dense"
                sx={{ mb: 3 }}
              />

              <DialogActions
                sx={{ justifyContent: "center", width: "100%", mt: 2 }}
              >
                <Button
                  variant="outlined"
                  onClick={onClose}
                  sx={{
                    width: "150px",
                    mr: 2,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  sx={{ width: "150px" }}
                >
                  Save
                </Button>
              </DialogActions>

              {/* Logout Button */}
              <Box sx={{ mt: 4, textAlign: "center" }}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={openLogoutModal}
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: "bold",
                    width: "150px",
                  }}
                >
                  Logout
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>

      {/* Logout Confirmation Modal */}
      <Modal open={isLogoutModalOpen} onClose={closeLogoutModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography sx={{ mb: 2, fontSize: "20px", fontWeight: "700" }}>
            Confirm Logout
          </Typography>
          <Typography sx={{ mb: 8 }}>
            Are you sure you want to logout?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-around", gap: 1 }}>
            <Button variant="outlined" onClick={closeLogoutModal}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleLogout}>
              Logout
            </Button>
          </Box>
        </Box>
      </Modal>
    </Dialog>
  );
};

export default ProfileEditPopup;
