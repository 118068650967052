import axios, { AxiosRequestConfig } from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || "http://localhost:3001",
  headers: {
    "Content-Type": "application/json",
  },
});

console.log(
  "Backend URL from the Frontend is and this is for redeploy: ",
  process.env.REACT_APP_BACKEND_URL || "http://localhost:3001"
);

axiosInstance.interceptors.request.use(async (config) => {
  if (!config.url?.includes("/logs")) {
    const token = localStorage.getItem("token");
    console.log("Token before request:", token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newToken = await refreshAuthToken();
      if (newToken) {
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return axiosInstance(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

const get = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  const response = await axiosInstance.get<T>(url, config);
  return response.data;
};

const post = async <T, U = any>(
  url: string,
  data: U,
  config?: AxiosRequestConfig
): Promise<T> => {
  const response = await axiosInstance.post<T>(url, data, config);
  return response.data;
};

const put = async <T, U = any>(
  url: string,
  data: U,
  config?: AxiosRequestConfig
): Promise<T> => {
  const response = await axiosInstance.put<T>(url, data, config);
  return response.data;
};

const patch = async <T, U = any>(
  url: string,
  data: U,
  config?: AxiosRequestConfig
): Promise<T> => {
  const response = await axiosInstance.patch<T>(url, data, config);
  return response.data;
};

const del = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  const response = await axiosInstance.delete<T>(url, config);
  return response.data;
};

export const httpService = {
  get,
  post,
  put,
  patch,
  delete: del,
};

async function refreshAuthToken(): Promise<string | null> {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    return null;
  }

  try {
    const response = await axios.post<{ accessToken: string }>(
      `${process.env.REACT_APP_BACKEND_URL}/tokens/refresh`,
      { refreshToken }
    );
    const newToken = response.data.accessToken;
    localStorage.setItem("token", newToken);
    return newToken;
  } catch (error) {
    console.error("Failed to refresh token", error);
    return null;
  }
}
