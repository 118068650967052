import { AxiosRequestConfig } from "axios";
import { httpService } from "./HttpServices";
import { HubSpotUser } from "./HubSpotService";

export interface Book {
  bookName: string;
  ownershipField: string;
  balancingField: string;
  teamUsers: HubSpotUser[];
  companyId: string;
}

export interface Books {
  data: Book[];
}

export interface BookNames {
  data: string[];
}

class BookService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = "/books/";
  }

  async createBook(book: Book, config?: AxiosRequestConfig): Promise<Book> {
    try {
      return await httpService.post<Book, Book>(
        `${this.baseUrl}create-book`,
        book,
        config
      );
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Fetch book names by companyId in query parameters
  async getBookNames(
    companyId: string,
    config?: AxiosRequestConfig
  ): Promise<BookNames> {
    try {
      const url = `${this.baseUrl}get-book-names?companyId=${companyId}`;
      return await httpService.get<BookNames>(url, config);
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Fetch books by companyId in query parameters
  async getBooks(
    companyId: string,
    config?: AxiosRequestConfig
  ): Promise<Books> {
    try {
      const url = `${this.baseUrl}get-books?companyId=${companyId}`;
      return await httpService.get<Books>(url, config);
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Fetch a single book by name and companyId in query parameters
  async getBookByName(
    name: string,
    companyId: string,
    config?: AxiosRequestConfig
  ): Promise<Book> {
    try {
      const url = `${this.baseUrl}get-book/${name}?companyId=${companyId}`;
      return await httpService.get<Book>(url, config);
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Update a book by name and companyId in the request body
  async updateBook(
    name: string,
    book: Partial<Book>,
    companyId: string,
    config?: AxiosRequestConfig
  ): Promise<Book> {
    try {
      const bookWithCompanyId = { ...book, companyId };
      return await httpService.put<Book, Partial<Book>>(
        `${this.baseUrl}update-book/${name}`,
        bookWithCompanyId,
        config
      );
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Delete a book by name and companyId in query parameters
  async deleteBook(
    name: string,
    companyId: string,
    config?: AxiosRequestConfig
  ): Promise<void> {
    try {
      const url = `${this.baseUrl}delete-book/${name}?companyId=${companyId}`;
      await httpService.delete<void>(url, config);
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  private handleError(error: any): void {
    // Handle error logging or display here
    console.error("API call failed:", error);
  }
}

export const bookService = new BookService();
